@for $i from 1 through 2 {
  $size: $i * 1.5rem;
  $x-size: $size * 0.5;
  .pt-#{$i} {
    padding-top: $size; }

  .pb-#{$i} {
    padding-bottom: $size; }

  .mt-#{$i} {
    margin-top: $size; }

  .mb-#{$i} {
    margin-bottom: $size; } }

.flex {
  display: flex;
  flex-direction: column;
  align-items: center; }

.shadow {
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.17); }


@media screen and (min-width: 42rem) {
  %grid {
    display: grid;
    grid-template-columns: 1fr; }

  [class*='grid-'] {
    grid-gap: 2rem; }

  .grid-2, .grid-3, .grid-4, .grid-auto, .grid-inverse {
    @extend %grid; }
  .grid-inverse {
    grid-template-columns: 70% 1fr;
    grid-column-gap: 4rem; }
  .grid-2 {
    grid-template-columns: 1fr 1fr; }
  .grid-3 {
    grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr)); }
  .grid-4 {
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr)); } }

$sites: ("facebook": #325c94, "twitter": #00abdc,"linkedin": #007bb6);

@each $item, $color in $sites {
  .#{$item} {
    svg {
      fill: $color; } } }

// 404 page
.never {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
  text-align: center; }

.inline {
  display: inline;
  margin: 0; }
